<template>
  <div class="w-full flex flex-col">
    <loader :loading="loading" :backdrop="true"/>
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-xl">Usuarios</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Listado de Empleados</h4>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-5 gap-4 mt-4">
              <input-field
                idInput="keyWord"
                idFormGroup="keyWord-group"
                type="text"
                label="Palabra Clave"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <select-input
                class="mx-2 w-full"
                idInput="filterByInput"
                idFormGroup="filterBy-group"
                label="Filtrar por"
                v-model="filterForm.filterBySelect"
                :options="filterListOptions"
              />
              <select-input
                class="w-full"
                idInput="operator"
                idFormGroup="operator-group"
                label="Operador"
                v-model="filterForm.operatorSelect"
                :options="operatorListOptions"
              />
              <input-field
                class="mx-2"
                idInput="from-date"
                idFormGroup="fromDate-group"
                type="date"
                label="Desde"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
              <input-field
                class="mx-2"
                idInput="to-date"
                idFormGroup="fromDate-group"
                type="date"
                label="Hasta"
                classLabel="mt-1"
                classInput="input-filter"
                v-model="filterForm.keyWord"
              />
            </div>
            <div class="flex grid grid-cols-3 gap-4 mt-4" v-if="isToggle">

            <input-field
              idInput="stateInput"
              idFormGroup="state-group"
              type="text"
              label="Estado"
              classLabel="mt-1"
              classInput="input-filter"
              v-model="filterForm.state"
            />
            <select-input
              idInput="sortBySelect"
              idFormGroup="sort-group"
              label="Ordenar por"
              v-model="filterForm.sortBy"
              :options="sortByListOptions"
            />
            <select-input
              idInput="sortWaySelect"
              idFormGroup="sortWay-group"
              label="Ordenar de forma"
              v-model="filterForm.agent"
              :options="sortWayListOptions"
            />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-between mb-4">
        <label class="self-center">Usuarios</label>
        <div class="flex p-2 bg-blue-700 rounded self-center">
          <icon @click.native="handleCreateAdmin" class="text-white" name="PlusIcon" />
        </div>
      </div>
      <table-user
        v-if="tableData.users.length>0"
        :users="tableData"
        :role="'Empleado'"
        @onChangePage="loadEmployees"
        @delete="deleteUser" />
      <div v-else class="text-center">
        <p>No hay resultados</p>
      </div>
    </section>
    <Modal v-show="isModalOpen"  :size="'sm'" @delete="deleteUser" @close="closeModal">
       <template v-slot:header>
        <div>
          <h1 class="text-gray-600 font-bold text-3xl mb-8">
            ¿Desea borrar este usuario?
          </h1>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <div class="flex justify-center">
            <button
              @click="isModalOpen = !isModalOpen"
              class="flex p-3 mr-2 bg-gray-400 ml-1 rounded self-center">
             <span class="text-white">Cancelar</span>
            </button>
            <button class="flex p-3 mr-1 bg-red-500 rounded self-center">
              <span class="text-white">Aceptar</span>
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { BForm } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import InputField from '@/components/InputField.vue';
import SelectInput from '@/components/SelectInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TableUser from '../../components/TableUser.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Icon from '@/components/Icon.vue';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';
import request from '@/api/request-services';

export default {
  name: 'Employee',
  components: {
    BForm,
    PlusIcon,
    MinusIcon,
    InputField,
    SelectInput,
    TableUser,
    BreadCrumbs,
    Icon,
    Modal,
    Loader,
  },
  data() {
    return {
      loading: false,
      isToggle: false,
      isModalOpen: false,
      tableData: {
        users: [],
        links: [],
        currentPage: 1,
        lastPage: 1,
      },
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: null,
        agent: null,
        state: '',
        payment: '',
        sortBy: null,
        office: null,
      },
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      filterListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Código, Nombre y/o Apellido, Correo, Cédula', value: 'all' },
        { text: 'Código', value: 'code' },
        { text: 'Nombre y/o Apellido', value: 'name' },
        { text: 'Teléfono', value: 'phone_number' },
        { text: 'Correo', value: 'email' },
        { text: 'Cédula', value: 'id' },
      ],
      operatorListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Contiene', value: 'contiene' },
        { text: 'Exacto', value: 'contiene' },
        { text: 'Mayor que', value: 'contiene' },
        { text: 'Menor que', value: 'contiene' },
      ],
      sortByListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'ID', value: 'id' },
      ],
      sortWayListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Decendiente', value: 'decendiente' },
        { text: 'Ascendiente', value: 'ascendiente' },
      ],
      // users: [
      //   {
      //     code: 'GV108430',
      //     name: 'Manuel Garro Campos',
      //     role: 'Employee',
      //     email: 'manuel@globalvastago.com',
      //     status: 1,
      //     date: '2021-01-28',
      //     last_login: '2021-01-28',
      //   },
      //   {
      //     code: 'GV108440',
      //     name: 'Alejandro González Poveda',
      //     role: 'Employee',
      //     email: 'alejandro@globalvastago.com',
      //     status: 1,
      //     date: '2021-01-28',
      //     last_login: '2021-01-28',
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      getToken: 'authentication/getAuthToken',
    }),
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
    setCategoryValue() {
      //
    },
    handleCreateAdmin() {
      const role = 2;
      this.$router.push({ name: 'Crear Usuario', params: { role } });
    },
    async loadEmployees(page = 1) {
      this.loading = true;
      const response = await request.requestGET(`user?role=employee&page=${page}`, this.getToken);

      if (response.status === 200) {
        this.tableData.users = response.data.data;
        this.tableData.links = response.data.links.slice(1, -1);
        this.tableData.currentPage = response.data.current_page;
        this.tableData.lastPage = response.data.last_page;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
    deleteUser() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
    this.loadEmployees();
  },
};
</script>
