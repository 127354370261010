<template>
  <div class="scroll">
    <div  v-if="users.lastPage > 1" class="w-2/12">
      <ul  class="pagination">
        <li v-if="users.currentPage > 1">
          <button @click="onChangePage(users.currentPage - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in users.links"
          :key="i"
          :class="{'active': link.label == users.currentPage}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="users.currentPage < users.lastPage">
          <button @click="onChangePage(users.currentPage + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div>
    <table class="w-full">
      <thead>
        <tr class="w-full headerTable text-center">
          <th class="th-Row">No.</th>
          <th class="th-Row">Código</th>
          <th class="th-Row">Nombre</th>
          <th class="th-Row">Rol</th>
          <th class="th-Row">Correo Electrónico</th>
          <th class="th-Row">Estado</th>
          <th class="th-Row">Fecha</th>
          <th class="th-Row">Última Vista</th>
          <th class="th-Row">Abrir</th>
          <th class="th-Row">Editar</th>
          <!-- <th class="th-Row">Borrar</th> -->
        </tr>
      </thead>
      <tbody>
        <template class="my-4" v-for="item in users.users">
          <tr class="rowTable my-2 text-center" v-bind:key="item.id" >
            <td class="td-Row">{{item.id}}</td>
            <td class="td-Row">
              <b class="text-information cursor-pointer">
                <router-link
                  class="no-underline"
                  :to="{ name: 'Información Usuario', params: { id: item.id } }">
                  {{`GV-${item.user_information.id}`}}
                </router-link>
              </b>
            </td>
            <td class="td-Row">
              {{item.user_information.first_name}}
              {{item.user_information.last_name}}
            </td>
            <td class="td-Row">
              <span v-if="role!=null" class="badge">
                {{role}}
              </span>
              <p v-else>N/A</p>
            </td>
            <td class="td-Row">{{item.email}}</td>
            <td class="td-Row">
              <check-icon class="active" v-if="item.account_complete" ></check-icon>
              <x-icon class="inactive" v-if="!item.account_complete" ></x-icon>
            </td>
            <td class="td-Row">{{formatDateTime(item.created_at)}}</td>
             <td class="td-Row">{{item.last_login === null ? 'N/A' : item.last_login}}</td>
            <td class="td-Row">
              <button
                @click="() => $router.push({
                  name: 'Información Usuario',
                  params: { id: item.id }
                })"
                class="px-2 py-2 text-white self-center rounded bg-gray-300">
                <icon class="text-center m-auto" name="ListIcon" />
              </button>
              <!-- <button>
                <vertical-dots @click.native="() => (isOpen = true, selectedIndex = i)" />
              </button>
              <div class="absolute w-24 right-4">
                <div v-if="isOpen && selectedIndex === i" class="relative">
                  <caret-icon class="text-gray-200 -mt-2  ml-12" />
                  <nav
                    :id="`nav-${i}`"
                    class="bg-gray-50 border -mt-2 p-2">
                    <ul class="-ml-6 text-left ul-custom">
                      <li @click="() => $router.push({
                        name: 'Información Usuario',
                        params: { id: item.id }
                      })" class="list-none cursor-pointer my-2 ">Abrir</li>
                      <li @click="() => $router.push({
                        name: 'Editar Informacion',
                        params: { id: item.id }
                      })"  class="list-none cursor-pointer">Editar</li>
                      <li @click="openModal(item.user_information.id)"
                        class="list-none my-2 cursor-pointer">Eliminar</li>
                    </ul>
                  </nav>
                </div>
              </div> -->
            </td>
            <td class="td-Row">
              <button
                @click="() => $router.push({
                  name: 'Editar Informacion',
                  params: { id: item.id }
                })"
                class="px-2 py-2 text-white self-center rounded bg-blue-600">
                <icon class="text-center m-auto" name="EditIcon" />
              </button>
            </td>
            <!-- <td class="td-Row">
              <button
                @click="openModal(item.user_information.id)"
                class="px-2 py-2 text-white self-center rounded bg-red-500">
                <icon class="text-center m-auto" name="TrashIcon" />
              </button>
            </td> -->
            <!-- <td class="td-Row">
              <chevron-down-icon
                :class="{toggleRow: isOpen && selectedIndex === i}"
                @click.native="toggleRow(i)" /></td>
          </tr> -->
          <!-- <tr
            class="rowTable flex flex-col text-center"
            v-bind:key="`insideRow${item.id}`"
            v-if="isOpen && selectedIndex === i">
            <p>Sub row</p> -->
          </tr>
        </template>
      </tbody>
    </table>
    <div  v-if="users.lastPage > 1" class="w-2/12 mt-2">
      <ul  class="pagination">
        <li v-if="users.currentPage > 1">
          <button @click="onChangePage(users.currentPage - 1)">
             <ChevronLeftIcon/>
          </button>
        </li>
        <li
          v-for="(link, i) in users.links"
          :key="i"
          :class="{'active': link.label == users.currentPage}"
          @click="link.label != '...' ? onChangePage(link.label) : {}">
          <button>{{link.label}}</button>
        </li>
        <li v-if="users.currentPage < users.lastPage">
          <button @click="onChangePage(users.currentPage + 1)">
            <ChevronRightIcon/>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/components/icons/CheckIcon.vue';
import XIcon from '@/components/icons/XIcon.vue';
// import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronRightIcon from '@/components/icons/chevronRightIcon.vue';
import ChevronLeftIcon from '@/components/icons/ChevronLeftIcon.vue';
import Icon from '@/components/Icon.vue';
// import Modal from '@/components/Modal.vue';
import TimezoneMixin from '@/mixins/TimezoneMixin';

const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
};

export default {
  name: 'TableUser',
  components: {
    CheckIcon, XIcon, ChevronRightIcon, ChevronLeftIcon, Icon,
  },
  mixins: [TimezoneMixin],
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    users: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isModalOpen: false,
      selectedIndex: -1,
      edit: {
        user: {
          name: '',
        },
      },
      links: [],
    };
  },
  methods: {
    toggleRow(i) {
      this.isOpen = !this.isOpen;
      this.selectedIndex = i;
    },
    onChangePage(page) {
      this.$emit('onChangePage', page);
    },
    openModal(id) {
      this.$emit('delete', id);
      this.isOpen = false;
    },
  },
  created() {
    this.links = this.users.links;
  },
};
</script>
<style scoped>

  a, a:hover {
      color: #0a58ca !important;
  }
  .rowTable {
    margin-top: 20px !important;
  }
  .isProcessing {
    @apply bg-blue-700 text-white px-6 py-2 rounded-full;
  }
  .isPendent {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .delete {
    @apply bg-red-600 text-white px-6 py-2 rounded-full;
  }
  .edit {
    @apply bg-blue-800 text-white px-6 py-2 rounded-full;
  }
  .isSuccess {
    @apply bg-green-500 text-white px-6 py-2 rounded-full;
  }
  .headerTable {
    background-color: var(--light-gray);
    padding: 1rem !important;
  }
  .th-Row {
    @apply text-sm;
    padding: 0.5rem !important;
    margin-bottom: 1rem;
    font-family: 'FreeSans';
  }
  .td-Row {
    @apply border-t border-gray-200 text-sm;
    padding: 1.5rem !important;
    font-family: 'FreeSans';
  }
  .badge {
    @apply text-sm px-2 font-medium text-white rounded;
    background-color: #0857A3;
  }
  .active {
    color: green;
  }
  .inactive {
    color: var(--primary-red)
  }
  .toggleRow {
    @apply transform rotate-180;
  }

.pagination {
  @apply flex justify-between
}

.pagination li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
  cursor: pointer;
}

.pagination li.active {
  background-color: #0857A3;
  color: white;
  border: 1px solid #0857A3;
  cursor: pointer;

}

.scroll {
  max-height: 45vh;
  overflow: auto;
  position:relative;
  z-index:1;
}

.inner-element{
   z-index: 2;
}

.ul-custom {
      padding-left: 2rem !important;
}
</style>
