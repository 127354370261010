import { render, staticRenderFns } from "./Employee.vue?vue&type=template&id=e5074a38&"
import script from "./Employee.vue?vue&type=script&lang=js&"
export * from "./Employee.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports